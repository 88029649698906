import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import {
  afterProcessTransactions as afterProcessTransactionsBase,
  createMetricTotalProcessor,
  createTransactionsProcessor,
} from '../default/metrics';
import { IAfterProcessTransactionsContext, ITransactionProcessResult } from '../default/metrics.type';

type DemoMetricDataModel = IMetricsDataModel & {
  normalizedMostRecentStage: string | null;
};

const labelByStage: Record<string, string> = {
  'series b': 'Growth',
  'series c': 'Growth',
  'series d': 'Growth',
  'series e': 'Growth',
  'series f': 'Growth',
};

function afterProcessTransactions(
  metric: DemoMetricDataModel & ITransactionProcessResult,
  context: IAfterProcessTransactionsContext
) {
  const stage = metric.normalizedMostRecentStage;
  const res = afterProcessTransactionsBase(metric, context);

  return {
    ...res,
    seedCore: labelByStage[stage?.toLocaleLowerCase() ?? ''] ?? 'Seed',
  };
}

export { createTransactionsProcessor, createMetricTotalProcessor, afterProcessTransactions };
